
import { defineComponent, onMounted, reactive, ref } from "vue";
import { getDevices,resetDevice } from "@/core/services/api/device";
import localstorage from "@/core/services/LocalstorageService";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { getLocalTimeString } from "@/core/utils/timeString";
import EditTitle from "./components/EditTitle.vue";
import { Modal } from "bootstrap";
import { searchItems } from "@/core/utils/tableSearch";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import OperatingIoTDialog from "./components/OperatingIoTDialog.vue"

export default defineComponent({
  components: {
    Datatable,
    EditTitle,
    OperatingIoTDialog
  },
  setup() {
    const router = useRouter()
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const devices: any = reactive([]);
    const tableData: any = reactive([]);
    const isLoading = ref<boolean>(false);

    const tableHeader = ref([
      {
        name: "廠商",
        key: "vendor",
        sortable: true,
      },
      {
        name: "類型",
        key: "type",
        sortable: true,
      },
      {
        name: "名稱",
        key: "name",
        sortable: true,
      },
      {
        name: "描述",
        key: "description",
        sortable: true,
      },
       {
        name: "是否為群組裝置",
        key: "isGroup",
        sortable: true,
      },
      {
        name: "建立時間",
        key: "createTime",
        sortable: true,
      },
      {
        name: "功能",
        key: "operate",
        sortable: true,
      },
    ]);
    const currentIoT:any = reactive({
      name: "",
      description: "",
    });

    let editTitleModal;
    const search = ref<string>("");
    const operatingIoTVisible = ref(false)

    const setDevices = async () => {
      let response = await getDevices(currentSpaceGuid);
      devices.splice(0, devices.length, ...response);
    };

    const setTableData = () => {
        tableData.splice(0, tableData.length, ...devices);
    };

    const tableSearch = () => {
      tableData.splice(
        0,
        tableData.length,
        ...searchItems(search.value, devices)
      );
    };

    const setCurrentIoT = (item) => {
      Object.assign(currentIoT, item);
      console.log('currentIot',currentIoT);
    };

    const openEditTitle = () => {
      editTitleModal.show();
    };

    onMounted(() => {
      editTitleModal = new Modal(document.getElementById("editTitle-modal"));
    });

    const toIoTDataPointCommand = () => {
      router.push({path:`dataPointCommand/${currentIoT.guid}/${currentIoT.name}`})
    }

    const toIoTScenarioList = () => {
      router.push({path:`scenarioList/${currentIoT.guid}`})
    }

    const toMappingList = () => {
      router.push({path:`mappingList/${currentIoT.guid}`})
    }

    const toScheduleList = () => {
      router.push({path:`scheduleList/${currentIoT.guid}`})
    }

    const resetIoT = async () => {
      await resetDevice(currentIoT.guid).then(() => {
        Swal.fire("已重新設置", "", "success");
      })
    }


    const init = async () => {
      isLoading.value = true;
      await setDevices();
      setTableData();
      isLoading.value = false;
    };
    init();

    return {
      tableData,
      tableHeader,
      setCurrentIoT,
      openEditTitle,
      currentIoT,
      tableSearch,
      search,
      init,
      toIoTDataPointCommand,
      toIoTScenarioList,
      toMappingList,
      toScheduleList,
      resetIoT,
      operatingIoTVisible,
      devices,
      getLocalTimeString
    };
  },
});
