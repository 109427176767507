import ApiService from "@/core/services/ApiService";

// 操作Enum點位指令
export function operationEnumDataPoint(deviceGuid,dataPointGuid,request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Device/${deviceGuid}/EnumDataPoint/${dataPointGuid}/Operation`,request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 操作Range點位指令
export function operationRangeDataPoint(deviceGuid,dataPointGuid,request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Device/${deviceGuid}/RangeDataPoint/${dataPointGuid}/Operation`,request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}