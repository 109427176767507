<template>
  <el-form
    label-position="left"
    @submit.prevent="onSubmit()"
    :model="formData"
    :rules="rules"
    ref="formRef"
    class="form"
    label-width="110px"
  >
    <el-form-item label="名稱" prop="name">
      <el-input placeholder="請輸入IoT名稱" v-model="formData.name"></el-input>
    </el-form-item>
    <el-form-item label="描述">
      <el-input
        placeholder="請輸入IoT描述"
        v-model="formData.description"
      ></el-input>
    </el-form-item>
    <div class="d-flex justify-content-end">
      <button class="btn btn-success" type="submit">送出</button>
    </div>
  </el-form>
</template>

<script lang='ts'>
import { defineComponent, reactive, ref, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { modifyDevice } from "@/core/services/api/device";

export default defineComponent({
  props: ["data"],
  setup(props,{emit}) {
    const formData = reactive({
      name: "",
      description: "",
    });
    const rules = {
      name: [{ required: true, message: "請輸入設施名稱", trigger: "blur" }],
    };
    const formRef = ref<null | HTMLFormElement>(null);

    const onSubmit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value!.validate(async (valid) => {
        if (valid) {
          await Swal.fire({
            title: "您確定要修改嗎?",
            confirmButtonText: "確定",
            denyButtonText: `取消`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              await modifyDevice(props.data.guid, formData).then(async() => {
                await Swal.fire("修改成功!", "", "success");
                emit("onModify");
              })
            }
          });
        }
      });
    };

    const setFormData = () => {
      Object.assign(formData, {
        name: props.data.name,
        description: props.data.description,
      });
    };

    watch(
      props.data,
      () => {
        setFormData();
      },
      {
        immediate: true,
        deep: true,
      }
    );

    return {
      formData,
      rules,
      onSubmit,
      formRef,
    };
  },
});
</script>

<style>
</style>