<template>
    <Dialog append-to-body title="操作裝置" v-model="dialogShow" width="55%">
        <div class="d-flex justify-content-between">
            <div>
                <div v-if="isLoading">
                    <span
                        class="spinner-grow spinner-grow"
                        style="width: 1rem; height: 1rem"
                        role="status"
                        aria-hidden="true"
                    ></span>
                    <i style="font-size: 16px">正在載入...</i>
                </div>
            </div>
            <el-button @click="init()" type="success">重新整理</el-button>
        </div>

        <Datatable
            :table-data="tableData"
            :table-header="tableHeader"
            :enable-items-per-page-dropdown="false"
            :openTextEnd="false"
            :striped="true"
        >
            <template v-slot:cell-type="{ row: tableData }">
                <span>{{ tableData.type }}</span>
            </template>
            <template v-slot:cell-permission="{ row: tableData }">
                <template
                    v-for="(item, index) in tableData.permission"
                    :key="index"
                >
                    <el-tag class="me-2" type="info" v-if="item == '讀'">{{
                        item
                    }}</el-tag>
                    <el-tag v-else type="success">{{ item }}</el-tag>
                </template>
            </template>
            <template v-slot:cell-status="{ row: tableData }">
                <span v-if="tableData.canRead">{{ tableData.status }}</span>
            </template>
            <template v-slot:cell-queryTime="{ row: tableData }">
                <span>{{ tableData.queryTime }}</span>
            </template>
            <template v-slot:cell-operate="{ row: tableData }">
                <el-dropdown
                    v-if="
                        tableData.data.valueType == 'Enum' && tableData.canWrite
                    "
                >
                    <span class="el-dropdown-link text-primary">
                        功能選單<i
                            class="el-icon-arrow-down el-icon--right"
                        ></i>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <template
                                v-for="(option, index) in tableData.operate"
                                :key="index"
                            >
                                <el-dropdown-item
                                    @click="
                                        handelOperationEnum(
                                            option.value,
                                            option.guid
                                        )
                                    "
                                    >{{ option.label }}</el-dropdown-item
                                >
                            </template>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
                <div v-else-if="tableData.canWrite">
                    <div class="d-flex">
                        <el-input-number
                            :min="tableData.operate.min"
                            :max="tableData.operate.max"
                            :step="tableData.operate.scale"
                            class="me-2"
                            v-model="rangeValueList[tableData.operate.guid]"
                            size="small"
                        />
                        <el-button
                            @click="
                                handelOperationRange(tableData.operate.guid, tableData.operate.scale)
                            "
                            type="primary "
                            size="small"
                            >送出</el-button
                        >
                    </div>
                    <i
                        >最小值:{{ tableData.operate.min*tableData.operate.scale }} 最大值:{{
                            tableData.operate.max*tableData.operate.scale
                        }}</i
                    >
                </div>
            </template>
        </Datatable>
    </Dialog>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, ref } from "vue";
import Dialog from "@/components/v-dialog/CustomizeDialog.vue";
import localstorage from "@/core/services/LocalstorageService";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import {
    operationEnumDataPoint,
    operationRangeDataPoint,
} from "@/core/services/api/dataPoint";
import { getDeviceStatus } from "@/core/services/api/deviceStatus";
import { getLocalTimeString } from "@/core/utils/timeString";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
    components: {
        Dialog,
        Datatable,
    },
    props: {
        modelValue: {
            type: Boolean,
            default: false,
        },
        device: {
            type: Object,
        },
    },
    setup(props, { emit }) {
        const dialogShow = computed({
            get: () => props.modelValue,
            set: (val) => {
                emit("update:modelValue", val);
            },
        });
        const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
        const tableData: any = reactive([]);
        const tableHeader = ref([
            {
                name: "類型",
                key: "type",
                sortable: true,
            },
            {
                name: "權限",
                key: "permission",
                sortable: true,
            },
            {
                name: "目前狀態",
                key: "status",
                sortable: true,
            },
            {
                name: "狀態更新時間",
                key: "queryTime",
                sortable: true,
            },
            {
                name: "操作",
                key: "operate",
                sortable: true,
            },
        ]);
        const typeMap = {
            AliveStatus: "存活狀態",
            Power: "電源狀態",
            AirMode: "冷氣模式",
            AirFanSpeed: "冷氣風速",
            AirTemperatureSetting: "冷氣溫度設定",
            AirTemperatureIndoor: "冷氣室內溫度",
            DoorLock: "門鎖",
            BatteryPercentage: "電池",
            LightLevel: "亮度",
            LightColorTemperature: "色溫",
            AirPanelLock: "冷氣面板控制",
            MoveUp: "向上轉動",
            MoveDown: "向下轉動",
            MoveStop: "窗簾停止",
            MoveToPosition: "移動至指定位置",
            CurrentPosition: "當前位置",
        };
        const enumerationMap = {
            UnKnow: "未知",
            Online: "線上",
            Offline: "離線",
            On: "電源開啟",
            Off: "電源關閉",
            Locked: "上鎖",
            UnLocked: "解鎖",
            AirCondition: "冷氣",
            Dehumidify: "除溼",
            Ventilation: "送風",
            Automatic: "自動",
            Heating: "暖氣",
            Manual: "手動",
            PanelUnLocked: "面板未鎖定",
            PanelLocked: "面板鎖定",
            StandBy: "等待",
            Down: "向下",
            Up: "向上",
            Stop: "停止",
            MoveToPosition: "移動至指定位置",
        };
        const permissionMap = {
            Read: "讀",
            Write: "寫",
        };
        const deviceStatus: any = reactive({});
        const isLoading = ref(false);
        const rangeValueList: any = reactive({});

        const getPermission = (permission) => {
            let permissionSplit = permission.split(", ");
            let permissionList: any = [];
            for (const item of permissionSplit) {
                permissionList.push(permissionMap[item]);
            }
            return permissionList;
        };

        const translateEnumeration = (name) => {
            if (enumerationMap[name] !== undefined) {
                return enumerationMap[name];
            } else {
                return name;
            }
        };

        const setDeviceStatus = async () => {
            let request = await getDeviceStatus(props.device!.guid);
            Object.assign(deviceStatus, request);
        };

        const getDataPointStatusByDataPoint = (dataPoint) => {
            if (dataPoint.valueType == "Enum") {
                return deviceStatus.enumDataPointsStatuses.filter(
                    (o) => o.dataPoint == dataPoint.guid
                )[0];
            } else {
                return deviceStatus.rangeDataPointStatus.filter(
                    (o) => o.dataPoint == dataPoint.guid
                )[0];
            }
        };

        const getOperateOption = (data) => {
            let operateOption: any = [];
            for (const item of data.enumList) {
                operateOption.push({
                    label: translateEnumeration(item.name),
                    value: item,
                    guid: data.guid,
                });
            }
            return operateOption;
        };

        const setTableData = () => {
            tableData.splice(0);
            let enumList = props.device!.enumDataPoints;
            for (const item of enumList) {
                const canRead= item.permission.includes("Read");
                let status = canRead?getDataPointStatusByDataPoint(item):"";
                tableData.push({
                    data: item,
                     canRead: canRead,
                    canWrite: item.permission.includes("Write"),
                    type: typeMap[item.type],
                    permission: getPermission(item.permission),
                    status: canRead?translateEnumeration(status.currentStatus.name):"",
                    queryTime: getLocalTimeString(status.queryTime),
                    operate: getOperateOption(item),
                });
            }

            let rangeList = props.device!.rangeDataPoints;
            for (const item of rangeList) {
                 const canRead= item.permission.includes("Read");
                let status = canRead?getDataPointStatusByDataPoint(item):"";
                tableData.push({
                    data: item,
                    canRead: canRead,
                    canWrite: item.permission.includes("Write"),
                    type: typeMap[item.type],
                    permission: getPermission(item.permission),
                    status:  canRead?status.currentStatus*item.scale: -1,
                    queryTime: getLocalTimeString(status.queryTime),
                    operate: {
                        max: item.max,
                        min: item.min,
                        scale: item.scale,
                        guid: item.guid,
                    },
                });
                rangeValueList[item.guid] = canRead?status.currentStatus*item.scale:-1;
            }
        };

        const handelOperationEnum = async (value, guid) => {
            let request = {
                name: value.name,
                id: value.id,
            };
            await operationEnumDataPoint(
                props.device!.guid,
                guid,
                request
            ).then(() => {
                Swal.fire("執行成功!", "", "success");
                setTimeout(() => {
                    init();
                }, 500);
            });
        };

        const handelOperationRange = async (guid, scale) => {
            let request = {
                command: rangeValueList[guid]/scale,
            };
            await operationRangeDataPoint(
                props.device!.guid,
                guid,
                request
            ).then(() => {
                Swal.fire("執行成功!", "", "success");
                setTimeout(() => {
                    init();
                }, 500);
            });
        };

        const init = async () => {
            isLoading.value = true;
            await setDeviceStatus();
            setTableData();
            isLoading.value = false;
        };
        init();

        return {
            dialogShow,
            tableData,
            tableHeader,
            isLoading,
            handelOperationEnum,
            handelOperationRange,
            rangeValueList,
            init,
        };
    },
});
</script>

<style scoped></style>
