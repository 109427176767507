<template>
  <div class="card">
    <div class="card-header border-0 pt-6 row">
      <!--begin::Card title-->
      <div class="row col d-flex">
        <div
          class="
            col-lg-3 col-12
            d-flex
            align-items-center
            position-relative
            my-1
          "
        >
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            v-model="search"
            @input="tableSearch()"
            class="form-control form-control-solid ps-15"
            placeholder="搜尋"
          />
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <div v-if="isLoading">
        <span
          class="spinner-grow spinner-grow"
          style="width: 1rem; height: 1rem"
          role="status"
          aria-hidden="true"
        ></span>
        <i style="font-size: 16px">正在載入...</i>
      </div>
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :enable-items-per-page-dropdown="true"
        :openTextEnd="false"
        :striped="true"
      >
        <template  v-slot:cell-vendor="{ row: tableData }">
          <span >{{ $t(tableData.vendor) }}</span>
        </template>
        <template v-slot:cell-type="{ row: tableData }">
          {{ $t(tableData.type) }}
        </template>
        <template v-slot:cell-name="{ row: tableData }">
          {{ tableData.name }}
        </template>
        <template v-slot:cell-description="{ row: tableData }">
          {{ tableData.description }}
        </template>
         <template v-slot:cell-isGroup="{ row: tableData }">

          {{ tableData.isGroup?"是":"否" }}
        </template>
        <template v-slot:cell-createTime="{ row: tableData }">
          {{ getLocalTimeString(tableData.createTime) }}
        </template>
        <template v-slot:cell-operate="{ row: tableData }">
          <el-dropdown>
            <span
              @mouseover="setCurrentIoT(tableData)"
              class="el-dropdown-link text-primary"
            >
              功能選單<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="resetIoT()"
                  >重設連線</el-dropdown-item
                >
                <el-dropdown-item @click="operatingIoTVisible = true"
                  >操作裝置</el-dropdown-item
                >
                <el-dropdown-item @click="toIoTDataPointCommand()"
                  >點位指令</el-dropdown-item
                >
                <el-dropdown-item @click="toIoTScenarioList()"
                  >裝置情境</el-dropdown-item
                >
                <el-dropdown-item @click="toMappingList()"
                  >裝置對應</el-dropdown-item
                >
                <el-dropdown-item @click="toScheduleList()"
                  >裝置排程</el-dropdown-item
                >
                <el-dropdown-item @click="openEditTitle()"
                  >修改名稱&amp;備註</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </Datatable>
    </div>
    <!-- 修改名稱&備註modal -->
    <div
      class="modal fade"
      id="editTitle-modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="flexibleAdded-exampleModalLabel">
              修改名稱&amp;備註
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <EditTitle @onModify="init()" :data="currentIoT"></EditTitle>
          </div>
        </div>
      </div>
    </div>
  </div>
  <OperatingIoTDialog
    v-if="operatingIoTVisible"
    v-model="operatingIoTVisible"
    :device="currentIoT"
  ></OperatingIoTDialog>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { getDevices,resetDevice } from "@/core/services/api/device";
import localstorage from "@/core/services/LocalstorageService";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { getLocalTimeString } from "@/core/utils/timeString";
import EditTitle from "./components/EditTitle.vue";
import { Modal } from "bootstrap";
import { searchItems } from "@/core/utils/tableSearch";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import OperatingIoTDialog from "./components/OperatingIoTDialog.vue"

export default defineComponent({
  components: {
    Datatable,
    EditTitle,
    OperatingIoTDialog
  },
  setup() {
    const router = useRouter()
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const devices: any = reactive([]);
    const tableData: any = reactive([]);
    const isLoading = ref<boolean>(false);

    const tableHeader = ref([
      {
        name: "廠商",
        key: "vendor",
        sortable: true,
      },
      {
        name: "類型",
        key: "type",
        sortable: true,
      },
      {
        name: "名稱",
        key: "name",
        sortable: true,
      },
      {
        name: "描述",
        key: "description",
        sortable: true,
      },
       {
        name: "是否為群組裝置",
        key: "isGroup",
        sortable: true,
      },
      {
        name: "建立時間",
        key: "createTime",
        sortable: true,
      },
      {
        name: "功能",
        key: "operate",
        sortable: true,
      },
    ]);
    const currentIoT:any = reactive({
      name: "",
      description: "",
    });

    let editTitleModal;
    const search = ref<string>("");
    const operatingIoTVisible = ref(false)

    const setDevices = async () => {
      let response = await getDevices(currentSpaceGuid);
      devices.splice(0, devices.length, ...response);
    };

    const setTableData = () => {
        tableData.splice(0, tableData.length, ...devices);
    };

    const tableSearch = () => {
      tableData.splice(
        0,
        tableData.length,
        ...searchItems(search.value, devices)
      );
    };

    const setCurrentIoT = (item) => {
      Object.assign(currentIoT, item);
      console.log('currentIot',currentIoT);
    };

    const openEditTitle = () => {
      editTitleModal.show();
    };

    onMounted(() => {
      editTitleModal = new Modal(document.getElementById("editTitle-modal"));
    });

    const toIoTDataPointCommand = () => {
      router.push({path:`dataPointCommand/${currentIoT.guid}/${currentIoT.name}`})
    }

    const toIoTScenarioList = () => {
      router.push({path:`scenarioList/${currentIoT.guid}`})
    }

    const toMappingList = () => {
      router.push({path:`mappingList/${currentIoT.guid}`})
    }

    const toScheduleList = () => {
      router.push({path:`scheduleList/${currentIoT.guid}`})
    }

    const resetIoT = async () => {
      await resetDevice(currentIoT.guid).then(() => {
        Swal.fire("已重新設置", "", "success");
      })
    }


    const init = async () => {
      isLoading.value = true;
      await setDevices();
      setTableData();
      isLoading.value = false;
    };
    init();

    return {
      tableData,
      tableHeader,
      setCurrentIoT,
      openEditTitle,
      currentIoT,
      tableSearch,
      search,
      init,
      toIoTDataPointCommand,
      toIoTScenarioList,
      toMappingList,
      toScheduleList,
      resetIoT,
      operatingIoTVisible,
      devices,
      getLocalTimeString
    };
  },
});
</script>

<style>
</style>